@font-face {
  font-family: "Artegra Sans";
  font-style: normal;
  font-weight: 700;
  font-display: optional;
  src: url("/fonts/artegra-sans-extrabold.woff2") format("woff2"),
    url("/fonts/artegra-sans-extrabold.woff") format("woff");
}
@font-face {
  font-family: "Artegra Sans";
  font-style: normal;
  font-weight: 600;
  font-display: optional;
  src: url("/fonts/artegra-sans-bold.woff2") format("woff2"),
    url("/fonts/artegra-sans-bold.woff") format("woff");
}
@font-face {
  font-family: "Open Sauce Sans";
  font-style: italic;
  font-weight: 400;
  font-display: optional;
  src: url("/fonts/open-sauce-sans-regular.woff2") format("woff2"),
    url("/fonts/open-sauce-sans-regular.woff") format("woff");
}
